import Vue from "vue";
import VueRouter from "vue-router";
import i18n from "@/plugins/i18n";
import EnvProvider from "@tt/vue-environment";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "login",
    component: () => import("@/views/Login"),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: "/products",
    name: "products",
    menuTitle: i18n.t("menu.title"),
    component: () => import("@/views/Products.vue"),
    meta: {
      requiresAuth: (EnvProvider.value("MUST_LOGIN") === "true"),
    }
  },
  {
    path: "/locations",
    name: "locations",
    menuTitle: i18n.t("locations.title"),
    component: () => import("@/views/Locations"),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: "/picking/:strategy",
    name: "picking",
    menuTitle: i18n.t("product.title"),
    component: () => import("@/views/Picking"),
    meta: {
      requiresAuth: true,
   }
  },
  {
    path: "/menu",
    name: "menu",
    menuTitle: i18n.t("menu.title"),
    component: () => import("@/views/Menu"),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: "/strategy",
    name: "strategy",
    menuTitle: i18n.tc("strategy.title", 1),
    component: () => import("@/views/Strategy"),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: "/crates",
    name: "crates",
    menuTitle: i18n.tc("crate.title", 1),
    component: () => import("@/views/CrateMenu.vue"),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: "/crate/:strategy",
    name: "crateOpen",
    menuTitle: i18n.tc("crate.title", 1),
    component: () => import("@/views/CrateOpen.vue"),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: "/crates/fill",
    name: "crateFill",
    menuTitle: i18n.tc("crate.fill", 2),
    component: () => import("@/views/CrateFill.vue"),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: "/crates/close",
    name: "crateClose",
    menuTitle: i18n.tc("crate.title", 2),
    component: () => import("@/views/CrateClose.vue"),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: "/product/:sku/detail",
    name: "product",
    menuTitle: i18n.t("loop.title"),
    component: () => import("@/views/Product.vue"),
    meta: {
      requiresAuth: (EnvProvider.value("MUST_LOGIN") === "true"),
    }
  },
  {
    path: "/product/:sku/stock",
    name: "product/stock",
    menuTitle: i18n.t("loop.title"),
    component: () => import("@/views/Stock.vue"),
    meta: {
      requiresAuth: (EnvProvider.value("MUST_LOGIN") === "true"),
    }
  },
  {
    path: "/order-menu",
    name: "orderMenu",
    menuTitle: i18n.t("menu.pickup-order-options.label"),
    component: () => import("@/views/OrderMenu.vue"),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: "/announce-order",
    name: "AnnounceOrder",
    menuTitle: i18n.t("menu.announce-order.label"),
    component: () => import("@/views/AnnounceOrder.vue"),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: "/pickup-order",
    name: "PickUpOrder",
    menuTitle: i18n.t("menu.pickup-order.label"),
    component: () => import("@/views/PickUpOrder.vue"),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: "/shipping",
    name: "shipping",
    menuTitle: i18n.t("menu.shipping.label"),
    component: () => import("@/views/Shipping.vue"),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: "/shipping/sorting",
    name: "shipping-sorting",
    menuTitle: i18n.t("menu.shipping.sorting.label"),
    component: () => import("@/views/shipping/Sorting.vue"),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: "/shipping/pack",
    name: "packing",
    menuTitle: i18n.t("menu.shipping.complete.label"),
    component: () => import("@/views/shipping/Packing.vue"),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: "/shipping/incomplete",
    name: "shipping-incomplete",
    menuTitle: i18n.t("menu.shipping.shipping-incomplete.label"),
    component: () => import("@/views/shipping/ShippingIncomplete.vue"),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: "/shipping/cancel-items",
    name: "cancel-items",
    menuTitle: i18n.t("menu.shipping.cancel.label"),
    component: () => import("@/views/shipping/Cancelling.vue"),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: "/shipping/ship",
    name: "ship",
    menuTitle: i18n.t("menu.shipping.ship.label"),
    component: () => import("@/views/shipping/Ship.vue"),
    meta: {
      requiresAuth: true,
    }
  }
];

// create router
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  const loggedIn = window.sessionStorage.getItem('authenticated');
  const isAuth = to.matched.some((record) => record.meta.requiresAuth);
  if (isAuth === true && loggedIn !== "true") {
    return next({ name: 'login' });
  }
  next();
});

export default router;
